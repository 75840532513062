export const environment = {
  production: true,
  accountApi: 'https://api.dev.irsbnk.co/empresas-2/account',
  holidaysApi: 'https://api.dev.irsbnk.co/empresas-2/holidays',
  loginApi: 'https://api.dev.irsbnk.co/empresas-2/login',
  otpApi: 'https://api.dev.irsbnk.co/empresas-2/otp',
  identityVerificationApi: 'https://api.dev.irsbnk.co/identity-verification/identityVerification',
  idVerificationApiKey: '0zrdYKUYlFs9uDilkZya55Y36JtmkyIoHKiJ5RYYDxmYvSXFsuSxYth0VsiTAKMn',
  websocketApi: 'wss://apiv2.dev.irsbnk.co/empresas-websocket-2',
  staticApiKey: 'V7hMyd5AU059Td27lUvIG1XoM5WiY2DI2DMFMer3',
  sessionConfiguration: {
    domain: 'empresas-2.dev.iristesting.com.co',
    path: '/',
    expires: 365,
    secure: true,
    sameSite: 'strict',
  },
  roleApi: 'https://api.dev.irsbnk.co/empresas-2/roles',
};
