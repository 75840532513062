import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  NgModule,
  LOCALE_ID,
  DEFAULT_CURRENCY_CODE,
  Injector,
  APP_INITIALIZER,
} from '@angular/core';
import {
  HttpClientModule,
  HTTP_INTERCEPTORS,
  HttpClient,
} from '@angular/common/http';
import {
  APP_BASE_HREF,
  LOCATION_INITIALIZED,
  registerLocaleData,
} from '@angular/common';
import es from '@angular/common/locales/es';
import { NgHttpLoaderModule } from 'ng-http-loader';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import {
  TranslateModule,
  TranslateLoader,
  TranslateService,
} from '@ngx-translate/core';

import { BaseModule } from './base/base.module';

import { PublicLayoutComponent } from './base/layouts/public-layout/public-layout.component';

import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';

import {
  assetUrl,
  IrisApplication,
  IrisBaseConfiguration,
  IrisBaseConstants,
} from '@iris/iris-base';
import {
  IrisAuthenticationConstants,
  IrisAuthenticationConfiguration,
  TokenInterceptor,
  ResponseInterceptor,
  AuthGuard,
} from '@iris/iris-authentication';
import { MultiTranslateHttpLoader } from 'ngx-translate-multi-http-loader';
import { HandleNoInternetService } from 'src/lib/services/network/handleNoInternet.service';
import { environment } from '../environments/environment';
import { HandleSessionControlService } from 'src/lib/services/session/handleSessionControl.service';
import { IrisLoginConfiguration, IrisLoginConstants } from '@iris/iris-login';
import { IrisOtpConfiguration, IrisOtpConstants } from '@iris/iris-otp';
import { IdVerificationApiKeyInterceptor } from 'src/lib/interceptors/idVerificationApiKey.interceptor';
import { IdVerificationConfiguration, IdVerificationConstants } from 'src/lib/interceptors/idVerification.types';

// Add colombia to Angular format
registerLocaleData(es);

export function AppInitializerFactory(
  translate: TranslateService,
  injector: Injector,
) {
  return () =>
    new Promise<any>((resolve: any) => {
      const locationInitialized = injector.get(
        LOCATION_INITIALIZED,
        Promise.resolve(null),
      );
      locationInitialized.then(() => {
        const langToSet = 'es';
        translate.setDefaultLang(langToSet);
        translate.use(langToSet).subscribe(() => resolve(null));
      });
    });
}

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new MultiTranslateHttpLoader(http, [
    { prefix: assetUrl('/i18n/'), suffix: '.json' },
    { prefix: assetUrl('/i18n/base.'), suffix: '.json' },
    { prefix: assetUrl('/i18n/otp.'), suffix: '.json' },
    { prefix: assetUrl('/i18n/login.'), suffix: '.json' },
  ]);
}

export function IrisBaseConfigurationFactory(
  handler: HandleNoInternetService,
): IrisBaseConfiguration {
  return {
    application: IrisApplication.BANCA_DIGITAL,
    handleNetworkIssues: handler,
  } as IrisBaseConfiguration;
}

export function IrisLoginConfigurationFactory(): IrisLoginConfiguration {
  return {
    loginApi: environment.loginApi,
  } as IrisLoginConfiguration;
}

export function IrisAuthenticationConfigurationFactory(
  handler: HandleSessionControlService,
): IrisAuthenticationConfiguration {
  return {
    accountApi: environment.accountApi,
    websocketApi: environment.websocketApi,
    holidaysApi: environment.holidaysApi,
    loginApi: environment.loginApi,
    staticApiKey: environment.staticApiKey,
    sessionConfiguration: environment.sessionConfiguration,
    handleSessionControlService: handler,
  } as IrisAuthenticationConfiguration;
}

@NgModule({
  declarations: [AppComponent, PublicLayoutComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    NgHttpLoaderModule.forRoot(),
    BaseModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
  ],
  exports: [NzDatePickerModule],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: AppInitializerFactory,
      deps: [TranslateService, Injector],
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ResponseInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: IdVerificationApiKeyInterceptor,
      multi: true,
    },
    { provide: APP_BASE_HREF, useValue: '' },
    { provide: LOCALE_ID, useValue: 'es-CO' },
    { provide: DEFAULT_CURRENCY_CODE, useValue: 'COP' },
    AuthGuard,
    {
      provide: IrisLoginConstants.CONFIGURATION,
      useFactory: IrisLoginConfigurationFactory,
    },
    {
      provide: IrisBaseConstants.CONFIGURATION,
      useFactory: IrisBaseConfigurationFactory,
      deps: [HandleNoInternetService],
    },
    {
      provide: IrisAuthenticationConstants.CONFIGURATION,
      useFactory: IrisAuthenticationConfigurationFactory,
      deps: [HandleSessionControlService],
    },
    {
      provide: IrisOtpConstants.CONFIGURATION,
      useValue: {
        otpApi: environment.otpApi,
      } as IrisOtpConfiguration,
    },
    {
      provide: IdVerificationConstants.CONFIGURATION,
      useValue: {
        idVerificationApiKey: environment.idVerificationApiKey,
        identityVerificationApi: environment.identityVerificationApi,
      } as IdVerificationConfiguration,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
